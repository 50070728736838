

















































































import { mapActions, mapGetters } from 'vuex'
import { required, email, minChars, maxChars, lowercase, uppercase, number, specialChar } from '@/calendesk/forms/validators'
import mixins from 'vue-typed-mixins'
import DialogMixins from '@/components/dialogs/mixins/DialogMixins'
import { DialogTypes } from '@/components/dialogs/DialogTypes'
import DialogSize from '@/calendesk/models/DialogSize'
import Dialog from '@/calendesk/models/Dialog'
import PaymentActions from '@/views/components/mixins/PaymentActions'
import { errorNotification } from '@/calendesk/prototypes/notifications'

export default mixins(DialogMixins, PaymentActions).extend({
  name: 'LoginDialogForm',
  data: () => ({
    loading: false,
    error: null,
    showPassword: false,
    loginForm: false,
    form: {
      email: null,
      password: null
    },
    rules: {
      required,
      email,
      minChars,
      maxChars,
      lowercase,
      uppercase,
      number,
      specialChar
    }
  }),
  computed: {
    ...mapGetters({
      getAfterLoginAction: 'user/getAfterLoginAction'
    }),
    loginExternalConfiguration (): Record<string, any> {
      return this.draftConfiguration.login_modal_configuration
    }
  },
  methods: {
    ...mapActions({ login: 'user/loginUserAndStoreSession' }),
    handleLogin (): void {
      (this.$refs.LoginForm as any).validate()

      if (!this.loginForm) {
        errorNotification('form_is_invalid', null, false)
        return
      }
      this.loading = true
      this.$emit('loading', this.loading)
      this.login(this.form)
        .then(() => {
          this.loading = false
          this.$emit('loading', this.loading)
          this.closeDialog2()

          if (this.getAfterLoginAction) {
            this.getAfterLoginAction()
            this.setAfterLoginAction(null)
          }
        })
        .catch(error => {
          this.loading = false
          this.$emit('loading', this.loading)

          if (error.response) {
            switch (error.response.status) {
              case 401:
                this.error = this.$trans('email_or_password_incorrect')
                break
              case 403:
                this.openDialog2(new Dialog(
                  true,
                  DialogTypes.ACCOUNT_ACTIVATION_REQUEST,
                  this.$trans('account_activation_resend_title'),
                  DialogSize.SMALL,
                  false,
                  'center',
                  {
                    email: this.form.email
                  }
                ))
                break
              default:
                this.error = this.$trans('error_occurred')
                break
            }
          } else {
            this.error = this.$trans('error_occurred')
          }
        })
    }
  }
})
